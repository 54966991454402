<template>
    <div class="status-btn">
        <div :class="'status-'+status">
            <i class="el-icon-warning-outline" v-if="status===1" title="未接收" ></i>
            <i class="el-icon-refresh" v-else-if="status===0" title="已接收"></i>
            <i class="el-icon-timer" v-else-if="status===2" title="进行中"></i>
            <i class="el-icon-success" v-else-if="status===3" title="待上传"></i>
            <i class="el-icon-remove" v-else-if="status===4" title="已放弃"></i>
            <i class="el-icon-success" v-else-if="status===5" title="已完成"></i>
            <span v-if="title!=''">{{title}}</span>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        status:{
            type:Number,
            default:0
        },
        title:{
            type:String,
            value:''
        }
    }
}
</script>
<style lang="less">
    .status-btn{
        font-size: 12px;
        i{
            font-size:18px;
            cursor: pointer;
        }
        .status-0{
            font-size: 0.8;
            color:rgb(13, 123, 214)
        }
        .status-1{
            font-size: 0.8;
            color:#999
        }
        .status-2{
            font-size: 0.8;
            color:rgb(33, 158, 207)
        }
        .status-3{
            font-size: 0.8;
            color:rgb(14, 172, 158)
        }
        .status-4{
            font-size: 0.8;
            color:rgb(151, 10, 76)
        }
        .status-5{
            font-size: 0.8;
            color:rgb(10, 173, 51)
        }
        span{
            margin:0 5px;
        }
    }
</style>