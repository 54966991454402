<template>
    <div>
        <Report ref="report" :data="task_data" :info="task_info" :record="task_record"/>
    </div>
</template>
<script>
import Report from '@/components/Report'
export default {
    components:{
        Report
    },
    data(){
        return{
            tid:'',
            task_info:{},
            task_data:{},
            task_record:[]
        }
    },
    created(){
        this.tid= this.$route.query.tid
        // this.getReport()
    },
    watch: {
        $route(){
            this.tid= this.$route.query.tid
            //console.log(this.tid,"---")
        },
        tid() {
            this.task_data = {}
            this.getReport()
        }
    },
    methods:{
        //获取报告详情
        getReport(){
            if(!this.tid){
                return
            }
            let params = {
                method:'getReport',
                tid:this.tid
            }
            //console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                let data = res.data||0
                if(data){
                    this.task_info = data.task_info,this.task_data = data.data
                    this.task_record = data.task_record
                    console.log(data)
                    document.title = this.task_info.client_name||'工单详情'
                    //更新获取实际使用时间
                    this.$refs.report.initUseTime()
                }else{
                   this.$message({
                        message: this.CONST.NO_RESULTS,
                        type: 'warning'
                    }); 
                }
            }).catch(err=>{
                //console.log(err.data||err)
                this.$alert(err.msg?err.msg:err, '提示', {
                confirmButtonText: '确定',
                callback: () => {
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                }
                });
                //err
                
            })
        }
    }
}
</script>