<template>
    <div class="page">
        <!-- taskInfo -->
        <el-card class="box-card">
            <el-descriptions title="工单信息" :column="2" size="medium">
                <el-descriptions-item label="客户名称">{{info_data.client_name}}</el-descriptions-item>
                <el-descriptions-item label="客户地址">{{info_data.address||'未获取到地址信息'}}</el-descriptions-item>
                <el-descriptions-item label="服务人员">{{info_data.staff_name}}</el-descriptions-item>
                <el-descriptions-item :label="timeTitle">
                    <div v-if="!record||!record[0]">
                        {{info_data.start_time|timeRange(info_data.end_time)}}
                    </div>
                    <div v-else-if="record[0]&&record[0].time">
                        {{record[0].time|timeRange(record[record.length-1].time)}}
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="有效服务时间">
                    {{spendTime}}
                    分钟
                </el-descriptions-item>
                <el-descriptions-item label="服务状态">
                     <Status :status="info_data.status*1" :title="info_data.status|statusFormat"/>
                </el-descriptions-item>
            </el-descriptions>
            <!-- 操作菜单 -->
                <div class="menu-list">
                    <el-row>
                     <el-button v-for="(item,index) in menuList" circle :type="item.type"
                     :key="index" :id="item.id" @click="menuClick(item.id)">
                        <i :class="item.icon" />
                     </el-button>
                    </el-row>
                </div>
        </el-card>
        <!-- taskDetail -->
        <div class="box-report">
            <ReportAudit v-if="info_data.project=='餐饮虫害防制'" :detail="detail"/>
            <slot></slot>
        </div>
        <!-- back to top -->
         <el-backtop target=".page"  :bottom="100">
         </el-backtop>
    </div>
</template>
<script>
import Status from '@/components/common/Status'
import ReportAudit from '@/components/ReportAudit'
export default {
    components:{
        Status,
        ReportAudit
    },
    props:{
        spendTime:{type:[String,Number]},
        data:{
            type:Object,
            value:{}
        },
        info:{
            type:Object,
            value:{}
        },
        record:{
            type:[Object,Array],
            value:{}

        }
    },
    watch:{
        data:{
            handler(value){
            //console.log("data改变",'----',value)
               this.detail = value
            },
            immediate: true
        },
        info:{
            handler(val){
                this.info_data = val 
                this.initUseTime()
            },
            immediate:true
        },
        record:{
            handler(val){
                if(val&&val[0]&&val[0].time)
                {
                    this.record = val 
                this.timeTitle = "服务时间"
                }
                //console.log("record",val)
            },
            immediate:true
        }
    },
    data(){
        return{
            timeTitle:"计划时间",
            menuList:[
                {
                    icon:'el-icon-download',
                    title:'下载',
                    type:"success",
                    id:"download"
                },{
                    icon:'el-icon-s-promotion',
                    title:'分享',
                    type:"warning",
                    id:"share"
                },{
                    icon:'el-icon-receiving',
                    title:'邮件',
                    type:"primary",
                    id:"email"
                },{
                    icon:'el-icon-time',
                    title:'操作记录',
                    type:"primary",
                    id:"history"
                }
            ],
            useTime:'',//有效服务时间
            info_data:{},//任务详情
            detail:{},//任务数据 
        }
    },
    filters:{
        getWorkTime(time1,time2){
            if(time1){
                return time1
            }
            return time2|0
        },
        //时间转换
        timeRange(start,end){
            if(!start||!end){
                return start
            }
            let startA = start.split(" "),endA = end.split(" ")
            if(startA[0]==endA[0]){
                //同一天
                return startA[0]+ ' '+ startA[1]+' 至 '+endA[1]
            }else{
                //不同天
                return start+'至'+end
            }
        }
    },
    methods:{
        initUseTime(){
            //console.log(this.data,this.info)
            if(this.data&&this.data.use_time){
                this.useTime = this.data.use_time
            }else if(this.record&&this.record.useTime){
                this.useTime = this.record.useTime
            }
        },
        menuClick(id){
            this.$emit(id,'')
        }
    }
}
</script>
<style lang="less">
.page{
    background: #F8F8F8;
    padding:40px 25px;
    border-radius: 10px;
    height: 100vh;overflow-x: hidden;
}
.info{
    padding:35px 25px;
    background: #FFFFFF;
}
.box-report{
    padding: 40px 0;
}
</style>