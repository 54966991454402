<template>
    <div>
        <div v-for="(item,index) in questionArr" :key="index" class="q_item">
        <el-card class="box-card" v-loading="loading">
            <div slot="header" class="clearfix">
                <!-- <el-tag type="danger">{{index + 1}}</el-tag> -->
                <el-button circle>{{index + 1}}</el-button>
                <span class="text-title">{{item.QItemsTitle}}</span>
            </div>
            <div class="text item">
                <div class="desc">
                    <el-tag :type="answer[index].answer=='C'?'danger':answer[index].answer=='M'?'warning':'success'">{{answer[index].answer|getStatus}}</el-tag>
                    <div class="d_item">
                        {{answer[index].desc}}
                    </div>
                </div>
                <el-divider></el-divider>
                <div class="images">
                    <el-image
                    v-for="(it,i) in answer[index].images"
                    :key="i"
                    :preview-src-list="imgList"
                    style="width: 150px; height: 150px"
                    :src="it.src|resetImgUrl(imgUrl)"
                    fit="contain"></el-image>
                </div>
            </div>
        </el-card>
        </div>
    </div>
</template>
<script>
import {resetImgUrl} from '@/utils/filters.js'
export default {
    props:{
        detail:{
            type:Object,
            value:{}
        }
    },
    watch:{
        detail:{
            handler(val){
                if(val&&val.aid){
                    this.detail = val 
                    this.getAudit()
                }
            },
            immediate:true
        }
    },
    data(){
        return {
            imgList:[],//图片
            imgUrl:this.API.imgUrl,
            loading:true,
            questionArr:[],//问题列表
            answer:[],//数据列表
        }
    },
    filters:{
        getStatus(status){
            let str = status
            switch(status){
                case 'S':
                str = '达标的'
                break;
                case 'm':
                str = '小缺陷'
                break;
                case 'M':
                str = '大缺陷'
                break;
                case 'C':
                str = '重大缺陷'
                break;
            }
            return str
        }
    },
    methods:{
        //初始化列表
        initList(){
            let that = this
            try {
               this.answer = JSON.parse(this.detail.answerArr) 
               //console.log(this.answer)
               this.answer.forEach(item=>{
                   item.images.forEach((it)=>{
                       if(it.src){
                           let m = resetImgUrl(it.src,this.API.imgUrl)
                           that.imgList.push(m)
                       }
                   })
               })
               //console.log(this.imgList)
            } catch (error) {
                this.$message({
                        message: error,
                        type: 'warning'
                });
            }
        },
        //获取审计题目
        getAudit(){
            let params = {
                method:"getAudit",
                aid:this.detail.aid||''
            }
            //console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
                this.loading = false
               //console.log(res)
               if(res.data){
                    let data= res.data[0]
                    ,list = JSON.parse(data.list)
                    this.questionArr = list.questionItems
                    //console.log(this.questionArr)
                    this.initList()
               }
            }).catch(err=>{
                this.loading = false
                //console.log(err.data||err)
                //err
                this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
            })
        }
    }
}
</script>
<style lang="less">
 .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .q_item{
      margin:10px 0;
  }
  .text-title{
      margin:0 10px;
      font-size: 1.2em;
      color: #333;
  }
  .desc{
      color: #666;
      .d_item{
          line-height: 35px;
      }
  }
  .images{
      display: flex;
      flex-wrap: wrap;
  }
</style>